(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/products-grid-widget/assets/javascripts/query-fulltraff-data-async.js') >= 0) return;  svs.modules.push('/components/sport/products-grid-widget/assets/javascripts/query-fulltraff-data-async.js');
"use strict";




const trinidad = svs.isServer ? require('trinidad-core').core : svs.core;
const products = svs.isServer ? trinidad.components.require('utils', 'products') : svs.utils.products;
const {
  productIds: {
    FULLTRAFF
  },
  getSpecificDrawName
} = products;
const getLogger = svs.isServer ? trinidad.logger : svs.core.log.getLogger;
const logger = getLogger('sport:products-grid-widget');
const getJackpots = svs.isServer ? trinidad.components.require('content', 'shared').jackpots.getJackpots : () => null;

const ttlCache = svs.isServer ? new trinidad.ttlcache() : {
  get: () => null,
  set: () => null
};
const ONE_MINUTE = 60 * 1000;
const USUAL_CACHE_TIME = 5 * ONE_MINUTE;
const SHORT_CACHE_TIME = ONE_MINUTE;
const DRAWSTATE_OPEN = 2;

async function queryFulltraffDataAsync(_ref) {
  let {
    cacheKey,
    req
  } = _ref;
  try {
    const cachedResult = ttlCache.get(cacheKey);
    if (cachedResult) {
      return cachedResult.drawData;
    }
    const rawApiData = await _queryFulltraffDraw({
      req
    });
    const finalFulltraffData = await _extractDataFromResponseAndIncludeJackpot({
      rawApiData,
      req
    });

    const newCachedResult = {
      drawData: finalFulltraffData
    };
    let cacheTime = SHORT_CACHE_TIME;
    if (finalFulltraffData.isOpen && finalFulltraffData.closeTime) {
      const millisecondsToCloseTime = new Date(finalFulltraffData.closeTime).getTime() - Date.now();
      cacheTime = millisecondsToCloseTime < USUAL_CACHE_TIME ? SHORT_CACHE_TIME : USUAL_CACHE_TIME;
    }
    ttlCache.set(cacheKey, newCachedResult, cacheTime);
    return newCachedResult.drawData;
  } catch (error) {
    logger.warn('Error when setting fulltraff data', error, req);
    return null;
  }
}

async function _queryFulltraffDraw(_ref2) {
  let {
    req
  } = _ref2;
  const drawName = getSpecificDrawName(FULLTRAFF);
  return trinidad.jupiter.callAsync({
    method: 'GET',
    path: "/draw/1/".concat(drawName, "/draws"),
    req
  });
}

async function _extractDataFromResponseAndIncludeJackpot(_ref3) {
  let {
    rawApiData,
    req
  } = _ref3;
  const {
    draws
  } = rawApiData || {};
  if (!Array.isArray(draws) || draws.length === 0) {
    return {
      isOpen: false
    };
  }
  const firstOpenDraw = draws.find(item => item.drawStateId === DRAWSTATE_OPEN);
  const {
    drawNumber,
    regCloseTime,
    productId
  } = firstOpenDraw || {};
  if (!drawNumber || !productId) {
    return {
      isOpen: false
    };
  }
  const jackpot = await _getJackpot({
    productId,
    drawNumber,
    req
  });
  return {
    isOpen: true,
    closeTime: regCloseTime,
    jackpot
  };
}

async function _getJackpot(_ref4) {
  let {
    productId,
    drawNumber,
    req
  } = _ref4;
  try {
    const allFulltraffJackpots = await getJackpots({
      product: productId,
      req
    });
    if (!Array.isArray(allFulltraffJackpots) || allFulltraffJackpots.length === 0) {
      return null;
    }
    const _extractJackpotData = _ref5 => {
      let {
        defaultJackpot,
        windiv,
        drawNumber,
        jackpotAmount
      } = _ref5;
      const jackpot = parseInt(jackpotAmount || 0, 10);
      return jackpot && !defaultJackpot && !windiv ? {
        drawNumber,
        jackpot
      } : null;
    };
    const allNonDefaultJackpots = allFulltraffJackpots.map(_extractJackpotData).filter(Boolean);
    const jackpotItem = allNonDefaultJackpots.find(item => (item === null || item === void 0 ? void 0 : item.drawNumber) === drawNumber);
    return (jackpotItem === null || jackpotItem === void 0 ? void 0 : jackpotItem.jackpot) || null;
  } catch (error) {
    logger.warn('queryFulltraffDataAsync(): Error when fetching jackpots', error, req);
    return null;
  }
}

if (svs.isServer) {
  module.exports = {
    queryFulltraffDataAsync
  };
} else {
  setGlobal('svs.sport.productsGridWidget.queryFulltraffDataAsync', queryFulltraffDataAsync);
}

 })(window);