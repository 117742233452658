(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/helper-get-game-url/assets/javascripts/get-game-my-bets-url.js') >= 0) return;  svs.modules.push('/components/lb-utils/helper-get-game-url/assets/javascripts/get-game-my-bets-url.js');
'use strict';

const isServer = svs.isServer;
const trinidad = isServer ? require('trinidad-core').core : {};
const jupiter = isServer ? trinidad.jupiter : svs.core.jupiter;
const urlMapping = isServer ? trinidad.urlMapping : svs.core.urlMapping;
const products = isServer ? trinidad.components.require('utils', 'products') : svs.utils.products;
const getGameMyBetsUrl = (_ref, callback) => {
  let {
    productId,
    wagerSerial,
    drawNumber,
    req
  } = _ref;
  const productClassName = products.getProductClassName(productId);
  const applySuffix = () => {
    if (products.isTipsetProduct(productId) || productId === products.productIds.BOMBEN) {
      return "/".concat(drawNumber);
    }
    return '';
  };
  const resolveExternalMyBetsUrl = () => "/externa-systemspel/mina-spel/".concat(productClassName, "/").concat(wagerSerial);
  const resolveNonExternalMyBetsUrl = () => {
    if (products.productIds.BOMBEN === productId) {
      return "/".concat(urlMapping.get("".concat(productClassName, "MyBetsHome")), "/").concat(wagerSerial);
    }
    if (products.isTipsetProduct(productId)) {
      return "/".concat(urlMapping.get("".concat(productClassName, "MyBetsHome")), "/").concat(wagerSerial);
    }
    return "/".concat(productClassName, "/mina-spel/").concat(wagerSerial).concat(applySuffix());
  };

  if (products.productIds.RACING === productId) {
    callback(null, "".concat(urlMapping.get('racingMyGames'), "/").concat(wagerSerial));
    return;
  }

  if (products.productIds.CHALLENGE === productId) {
    callback(null, "/challenge/mina-spel/".concat(wagerSerial));
    return;
  }
  if (products.isTipsetProduct(productId)) {
    callback(null, "".concat(urlMapping.get("".concat(productClassName, "MyBetsHome")), "/").concat(wagerSerial));
    return;
  }
  if (isServer) {
    jupiter.call({
      method: 'GET',
      path: "/player/1/wagers/".concat(wagerSerial),
      req
    }, (err, response) => {
      if (err) {
        callback(err);
      } else {
        callback(null, response !== null && response !== void 0 && response.wager.isUserBoards ? resolveExternalMyBetsUrl() : "".concat(resolveNonExternalMyBetsUrl()));
      }
    });
  } else {
    jupiter.get("/player/1/wagers/".concat(wagerSerial), function () {
      let {
        wager
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      callback(null, wager.isUserBoards ? resolveExternalMyBetsUrl() : "".concat(resolveNonExternalMyBetsUrl()));
    }, callback);
  }
};
if (svs.isServer) {
  module.exports = getGameMyBetsUrl;
} else {
  setGlobal('svs.components.lbUtils.getGameMyBetsUrl', getGameMyBetsUrl);
}

 })(window);