(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_actions/swish-button/assets/javascripts/init.js') >= 0) return;  svs.modules.push('/components/banner_actions/swish-button/assets/javascripts/init.js');
"use strict";


const logger = new svs.core.log.Logger('banner_action:swish-button');
const startDeposit = () => {
  const depositValues = {
    amount: 100,
    skipStepOne: false
  };
  const storageTimeInMinutes = 30;
  svs.components.Storage.browser.set('depositValues', 'quick-deposit', depositValues, storageTimeInMinutes, error => {
    if (error) {
      logger.warn("Failed to set depositValues: ".concat(error));
    }
  });
};
const trackSwishAction = node => {
  const {
    trackingString
  } = node.dataset;
  svs.components.analytics.trackPromoClick({
    action: 'initiate',
    opt_label: trackingString,
    category: 'Swish'
  });
};
const handleClick = node => {
  trackSwishAction(node);
  startDeposit();
};
const setupEventListener = () => {
  const nodes = document.querySelectorAll('.js-banner-actions-swish-button');
  nodes.forEach(node => {
    node.addEventListener('click', () => {
      handleClick(node);
    });
  });
};
const init = () => {
  setupEventListener();
};
setGlobal('svs.banner_actions.swish_button.init', init());

 })(window);