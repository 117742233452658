(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/accountservices/playscan-log/assets/javascripts/playscan-log.js') >= 0) return;  svs.modules.push('/components/accountservices/playscan-log/assets/javascripts/playscan-log.js');

'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const logger = svs.core.log.getLogger('accountservices:playscan-log');
class PlayscanLog {
  constructor() {
    var _svs$core$config$data;
    this.userKey = null;
    this.playscanJsonUrl = "".concat(svs.core.urlMapping.get('mygamingSelfTest'), "/playscan/playscan-ws/json");
    this.isCustomerSupport = svs.core.userSession.hasRole(svs.core.userSession.roles.CUSTOMERSERVICE);
    this.site = ((_svs$core$config$data = svs.core.config.data) === null || _svs$core$config$data === void 0 || (_svs$core$config$data = _svs$core$config$data.svsconfig) === null || _svs$core$config$data === void 0 ? void 0 : _svs$core$config$data.site) === 'momang' ? 'pnp' : 'default';
  }
  async getPlayscanUserKey() {
    var _playscanResult;
    if (this.userKey) {
      return this.userKey;
    }
    let playscanResult;
    try {
      playscanResult = await svs.core.jupiter.callAsync({
        method: 'GET',
        path: '/player/1/playscankeys'
      });
    } catch (err) {
      var _err$responseJSON;
      const errorCode = err === null || err === void 0 || (_err$responseJSON = err.responseJSON) === null || _err$responseJSON === void 0 || (_err$responseJSON = _err$responseJSON.error) === null || _err$responseJSON === void 0 ? void 0 : _err$responseJSON.code;
      if (errorCode !== 666) {
        if (err && err.responseJSON) {
          logger.error('Error getting Playscan userKey from /player/1/playscankeys', err.responseJSON);
        } else {
          logger.error('Error getting Playscan userKey from /player/1/playscankeys');
        }
      }
    }
    this.userKey = (_playscanResult = playscanResult) === null || _playscanResult === void 0 || (_playscanResult = _playscanResult.response) === null || _playscanResult === void 0 || (_playscanResult = _playscanResult.playscan) === null || _playscanResult === void 0 ? void 0 : _playscanResult.key;
    return this.userKey;
  }

  async postToPlayscan(_ref) {
    let {
      intent = '',
      payload = {},
      messageId = '',
      session_id = '',
      page = ''
    } = _ref;
    this.userKey = this.userKey || (await this.getPlayscanUserKey());
    if (!this.userKey) {
      logger.warn("Not posting to playscan since userKey is missing. Userkey is: ".concat(this.userKey));
      return false;
    }
    const eventData = {
      userKey: this.userKey,
      intent,
      messageId,
      page,
      payload,
      session_id,
      read_only: this.isCustomerSupport ? '1' : '0',
      site: this.site
    };
    try {
      const response = await fetch("".concat(this.playscanJsonUrl, "/Event?userKey=").concat(this.userKey, "&read_only=").concat(this.isCustomerSupport ? '1' : '0'), {
        method: 'POST',
        body: JSON.stringify(_objectSpread({}, eventData))
      });
      const responseJson = await response.json();
      if (!responseJson || responseJson !== null && responseJson !== void 0 && responseJson.error) {
        logger.error('Error posting to playscan event', responseJson === null || responseJson === void 0 ? void 0 : responseJson.error);
        return false;
      }
      return true;
    } catch (e) {
      logger.error('Error posting to playscan event', e === null || e === void 0 ? void 0 : e.responseJSON);
      return false;
    }
  }
}
svs.accountservices = svs.accountservices || {};
svs.accountservices.playscanLog = new PlayscanLog();

 })(window);