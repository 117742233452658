(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/products-grid-widget/assets/javascripts/prepare-open-fulltraff-item.js') >= 0) return;  svs.modules.push('/components/sport/products-grid-widget/assets/javascripts/prepare-open-fulltraff-item.js');
"use strict";




const trinidad = svs.isServer ? require('trinidad-core').core : svs.core;
const products = svs.isServer ? trinidad.components.require('utils', 'products') : svs.utils.products;
const {
  productIds: {
    FULLTRAFF
  },
  getSpecificDrawName
} = products;

const {
  GridItemSizes
} = svs.isServer ? require('./constants.es6') : svs.sport.productsGridWidget.constants;

const productsGridWidgetMockable = svs.isServer ? require('./query-fulltraff-data-async.es6') : svs.sport.productsGridWidget;

async function prepareOpenFulltraffItem(_ref) {
  let {
    itemConfig,
    isMobile,
    size,
    trackingPrefix,
    widgetId,
    req
  } = _ref;
  if (size === GridItemSizes.LARGE) {
    throw new Error('prepareOpenFulltraffItem() failed: Size "large" not supported, no image-data in CMS-config');
  }
  const {
    branding,
    images,
    filterOpacity,
    gradientOpacity,
    title,
    subTitle,
    urlMapping,
    bannerText
  } = itemConfig;

  const {
    userSession
  } = svs.isServer ? req : svs.core;
  const isLoggedInAsAdmin = userSession.hasRole(userSession.roles.INTERNAL);
  const cacheKey = "productsGridTipsen-".concat(widgetId, "-").concat(getSpecificDrawName(FULLTRAFF));
  const {
    isOpen,
    jackpot
  } = (await productsGridWidgetMockable.queryFulltraffDataAsync({
    cacheKey,
    req
  })) || {};
  if (!isOpen) {
    return null;
  }
  const imageMobile = _chooseImage({
    images,
    isMobile: true,
    size
  });
  const imageDesktop = _chooseImage({
    images,
    isMobile: false,
    size
  });
  const displayBanner = !jackpot && typeof bannerText === 'string' && bannerText.trim() !== '';
  const _getWarningMessage = () => {
    const targetsWithoutImageData = [!imageMobile && 'mobile', !imageDesktop && 'desktop'].filter(Boolean);
    if (targetsWithoutImageData.length > 0) {
      return "Image missing for size \"".concat(size, "\" (").concat(targetsWithoutImageData.join(', '), ")");
    }
    if (!urlMapping) {
      return 'URL-mapping missing';
    }
    return null;
  };
  const warning = isLoggedInAsAdmin ? _getWarningMessage() : null;
  const trackingString = "".concat(trackingPrefix, "/single-product/").concat(branding);
  return {
    branding,
    imageMobile,
    imageDesktop,
    filterOpacity,
    gradientOpacity,
    title,
    subTitle,
    warning,
    urlMapping,
    size,
    trackingString,
    displayBanner,
    bannerText,
    logoText: jackpot ? 'Jackpot!' : null,
    typeOfSpeechBubble: jackpot ? 'speech-bubble/speech_bubble_sport_1' : null,
    speechBubbleRotation: jackpot ? 'positive' : null,
    isSecondSelectedProduct: true
  };
}

function _chooseImage(_ref2) {
  let {
    images,
    isMobile,
    size
  } = _ref2;
  const imageCategory = {
    [GridItemSizes.SMALL]: isMobile ? 'mobileSmall' : 'desktopSmall',
    [GridItemSizes.MEDIUM]: isMobile ? 'mobileMedium' : 'desktopMedium',
    [GridItemSizes.SPECIAL_MEDIUM_OR_SMALL]: isMobile ? 'mobileMedium' : 'desktopSmall'
  }[size];
  return images[imageCategory];
}

if (svs.isServer) {
  module.exports = {
    prepareOpenFulltraffItem
  };
} else {
  setGlobal('svs.sport.productsGridWidget.prepareOpenFulltraffItem', prepareOpenFulltraffItem);
}

 })(window);