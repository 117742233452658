(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_actions/swish-button/views/swish-button.js') >= 0) return;  svs.modules.push('/components/banner_actions/swish-button/views/swish-button.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.banner_actions=_svs.banner_actions||{};
_svs.banner_actions.swish_button=_svs.banner_actions.swish_button||{};
_svs.banner_actions.swish_button.templates=_svs.banner_actions.swish_button.templates||{};
svs.banner_actions.swish_button.templates.swish_button = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "  Sätt in via\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"href":(lookupProperty(helpers,"urlMapping")||(depth0 && lookupProperty(depth0,"urlMapping"))||alias2).call(alias1,"quickDeposit",{"name":"urlMapping","hash":{},"data":data,"loc":{"start":{"line":10,"column":7},"end":{"line":10,"column":34}}}),"bank":"swish","icon-position":"right","default":true,"block":false,"size":300,"rounded":true,"class":"js-banner-actions-swish-button swish-button","data-tracking-string":(depth0 != null ? lookupProperty(depth0,"clickTrackingString") : depth0)},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":13,"column":7}}})) != null ? stack1 : "")
    + "\n\n\n";
},"useData":true});
Handlebars.partials['components-banner_actions-swish_button-swish_button'] = svs.banner_actions.swish_button.templates.swish_button;
})(svs, Handlebars);


 })(window);