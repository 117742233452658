(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/common/assets/javascripts/constants.js') >= 0) return;  svs.modules.push('/components/sportinfo/common/assets/javascripts/constants.js');
"use strict";


const SportradarWidgetTypes = svs.isServer ? require('trinidad-core').core.components.require('sport', 'sportradar').api.constants : svs.sport.sportradar.constants;

const SportinfoPathNames = {
  News: 'nyheter',
  Table: 'tabell',
  Statistic: 'statistik',
  Lineup: 'laguppstallning',
  Analyze: 'speltips',
  MatchInfo: 'matchinfo',
  Mutuals: 'inbordes-moten',
  Events: 'handelser',
  PlayMakerXstat: 'xstats',
  EnetpulseStats: 'enetpulse-stats',
  EnetpulseH2H: 'enetpulse-h2h'
};
const SportinfoSportradarWidgets = {
  HeadToHead: 'H2H',
  LiveTable: 'LT',
  MatchLineups: 'ML',
  Scoreboard: 'SB',
  GeneralStatistics: 'GS',
  LiveMatchTracker: 'LMT',
  MatchPreview: 'MP'
};
const SportradarWidgetToType = {
  [SportinfoSportradarWidgets.HeadToHead]: SportradarWidgetTypes.HEAD_TO_HEAD,
  [SportinfoSportradarWidgets.LiveTable]: SportradarWidgetTypes.LIVE_TABLE,
  [SportinfoSportradarWidgets.MatchLineups]: SportradarWidgetTypes.MATCH_LINEUPS,
  [SportinfoSportradarWidgets.Scoreboard]: SportradarWidgetTypes.MATCH_SCOREBOARD,
  [SportinfoSportradarWidgets.GeneralStatistics]: SportradarWidgetTypes.GENERAL_STATISTICS,
  [SportinfoSportradarWidgets.LiveMatchTracker]: SportradarWidgetTypes.MATCH_LMT,
  [SportinfoSportradarWidgets.MatchPreview]: SportradarWidgetTypes.MATCH_PREVIEW
};

const SweHockeyWidgetTypes = {
  MatchStatus: 'game.status',
  MatchGeneralInfo: 'game.information',
  Lineup: 'game.information.players',
  Events: 'game.information.events',
  TeamCaptain: 'game.information.officials',
  Referees: 'game.information.referees',
  GamePreview: 'game.preview.total',
  PeriodStatistics: 'game.statistics.periods',
  TotalStatistics: 'game.statistics.total',
  LiveTable: 'standings.game.total',
  LiveTableDropdown: 'standings.game.tournament'
};
const ContentProvider = {
  SportRadar: 'SportRadar',
  SweHockey: 'SweHockey',
  Svs: 'SvenskaSpel',
  PlayMakerGoal: 'PlayMakerGoal',
  PlayMakerXstat: 'PlayMakerXstat',
  EnetPulse: 'EnetPulse'
};
const SvsViewType = {
  MatchInfo: 'MatchInfo',
  MatchInfoFulltraff: 'MatchInfoFulltraff',
  News: 'News',
  Analyze: 'Analyze',
  PlayMakerXstat: 'PlayMakerXstat'
};
const EnetPulseViewType = {
  HeadToHead: 'H2H',
  TournamentStandings: 'TS',
  MatchIncidents: 'MI',
  MatchFormations: 'MF',
  WinProbability: 'WP',
  MatchDetails: 'MD',
  MatchStatistics: 'MS'
};
const ExternalIdProviders = {
  BetRadar: 'sportradarId',
  SportRadar: 'sportradarId',
  SweHockey: 'SweHockey',
  Kambi: 'Kambi'
};
const getSelectedOnPaths = (basePaths, component) => {
  const createBasePaths = (basePaths, sportInfoTab) => basePaths.map(basePath => {
    if (basePath.startsWith('/')) {
      return "".concat(basePath).concat(sportInfoTab ? "/".concat(sportInfoTab) : '');
    }
    return "/".concat(basePath).concat(sportInfoTab ? "/".concat(sportInfoTab) : '');
  });
  switch (component) {
    case SportinfoPathNames.MatchInfo:
      return ['/', "/".concat(SportinfoPathNames.MatchInfo), ...createBasePaths(basePaths)];
    case SportinfoPathNames.News:
      return ["/".concat(SportinfoPathNames.News), ...createBasePaths(basePaths, SportinfoPathNames.News)];
    case SportinfoPathNames.Table:
      return ["/".concat(SportinfoPathNames.Table), ...createBasePaths(basePaths, SportinfoPathNames.Table)];
    case SportinfoPathNames.Statistic:
      return ["/".concat(SportinfoPathNames.Statistic), ...createBasePaths(basePaths, SportinfoPathNames.Statistic)];
    case SportinfoPathNames.Lineup:
      return ["/".concat(SportinfoPathNames.Lineup), ...createBasePaths(basePaths, SportinfoPathNames.Lineup)];
    case SportinfoPathNames.Analyze:
      return ["/".concat(SportinfoPathNames.Analyze), ...createBasePaths(basePaths, SportinfoPathNames.Analyze)];
    case SportinfoPathNames.Mutuals:
      return ["/".concat(SportinfoPathNames.Mutuals), ...createBasePaths(basePaths, SportinfoPathNames.Mutuals)];
    case SportinfoPathNames.Events:
      return ["/".concat(SportinfoPathNames.Events), ...createBasePaths(basePaths, SportinfoPathNames.Events)];
    case SportinfoPathNames.PlayMakerXstat:
      return ["/".concat(SportinfoPathNames.PlayMakerXstat), ...createBasePaths(basePaths, SportinfoPathNames.PlayMakerXstat)];
    case SportinfoPathNames.EnetpulseStats:
      return ["/".concat(SportinfoPathNames.EnetpulseStats), ...createBasePaths(basePaths, SportinfoPathNames.EnetpulseStats)];
    case SportinfoPathNames.EnetpulseH2H:
      return ["/".concat(SportinfoPathNames.EnetpulseH2H), ...createBasePaths(basePaths, SportinfoPathNames.EnetpulseH2H)];
    default:
      return [];
  }
};
const EventTypes = {
  EvenType1X2: '1X2'
};
const EventTypeId = {
  EVENT_1X2: 1,
  HALFTIME_FULLTIME: 2,
  RESULT: 3,
  OVER_UNDER: 4,
  DOUBLECHANCE: 5,
  HANDICAP: 6,
  WINNER: 7,
  FIRST_GOAL_SCORER: 8,
  DUEL: 9,
  PLACING: 10,
  FORECAST: 11,
  HEAD_TO_HEAD: 12,
  LAST_GOAL_SCORER: 13,
  GOALS_BY_TEAM: 14,
  GOALS_BY_PLAYER: 15,
  AMOUNT: 16,
  PENALTY_GOALS: 18,
  PROFIT_MARGIN: 19,
  ODD_EVEN: 20,
  ALL_COMB_HOLDERS: 21,
  ALL_COMB_PARTICIPANTS: 24,
  GOAL_COUNT: 25,
  FIRST_GOAL: 26,
  LAST_GOAL: 27,
  LEAST_AMOUNT_OF_GOALS: 28,
  WIN_WITH_ZEROING: 29,
  EVENT_1X2_OVER_UNDER: 30,
  EVENT_1_2: 31,
  SCORECAST: 32
};
const EventTypeField = {
  Odds: 'odds',
  StartOdds: 'startOdds',
  TioTidnigarTips: 'tioTidningarTips',
  Distributions: 'distributions',
  Favourites: 'favourites',
  OverUnder: 'overUnder',
  FavouriteOdds: 'favouriteOdds'
};
const EventTypeDistributionsFallback = 'Global';
const EventTypeFieldType = {
  Odds: 'odds',
  Count: 'count',
  Percentage: 'percentage'
};
const TypeForEventTypeField = {
  [EventTypeField.Odds]: EventTypeFieldType.Odds,
  [EventTypeField.StartOdds]: EventTypeFieldType.Odds,
  [EventTypeField.Odds]: EventTypeFieldType.Odds,
  [EventTypeField.TioTidnigarTips]: EventTypeFieldType.Count,
  [EventTypeField.Distributions]: EventTypeFieldType.Percentage,
  [EventTypeField.Favourites]: EventTypeFieldType.Percentage,
  [EventTypeField.OverUnder]: EventTypeFieldType.Odds
};
const AllKnownOutcomes = {
  [EventTypeId.EVENT_1X2]: ['one', 'x', 'two'],
  [EventTypeId.GOAL_COUNT]: ['0', '1', '2', '3', '4', '5', '6', '7'],
  [EventTypeId.OVER_UNDER]: ['over', 'under']
};
const AllKnownOutcomesDescription = {
  [EventTypeId.EVENT_1X2]: ['Hemma', 'Oavgjort', 'Borta'],
  [EventTypeId.GOAL_COUNT]: ['0', '1', '2', '3', '4', '5', '6', '7+']
};
const OutcomeToAlphabeticStrings = {
  [AllKnownOutcomes[EventTypeId.EVENT_1X2][0]]: 'one',
  [AllKnownOutcomes[EventTypeId.EVENT_1X2][1]]: 'x',
  [AllKnownOutcomes[EventTypeId.EVENT_1X2][2]]: 'two',
  1: 'one',
  X: 'x',
  2: 'two'
};
const SportinfoMenuItems = {
  [SportinfoPathNames.MatchInfo]: {
    to: {
      pathname: ''
    },
    label: 'Matchinfo',
    testIdSuffix: 'matchinfo',
    componentKey: SportinfoPathNames.MatchInfo,
    getPath: basePath => "".concat(basePath)
  },
  [SportinfoPathNames.News]: {
    to: {
      pathname: "/".concat(SportinfoPathNames.News)
    },
    label: 'Nyheter',
    testIdSuffix: 'tt-news',
    componentKey: SportinfoPathNames.News,
    getPath: basePath => "".concat(basePath).concat(SportinfoPathNames.News)
  },
  [SportinfoPathNames.Table]: {
    to: {
      pathname: "/".concat(SportinfoPathNames.Table)
    },
    label: 'Tabell',
    testIdSuffix: 'table',
    componentKey: SportinfoPathNames.Table,
    getPath: basePath => "".concat(basePath).concat(SportinfoPathNames.Table)
  },
  [SportinfoPathNames.Statistic]: {
    to: {
      pathname: "/".concat(SportinfoPathNames.Statistic)
    },
    label: 'Statistik',
    testIdSuffix: 'statistic',
    componentKey: SportinfoPathNames.Statistic,
    getPath: basePath => "".concat(basePath).concat(SportinfoPathNames.Statistic)
  },
  [SportinfoPathNames.Lineup]: {
    to: {
      pathname: "/".concat(SportinfoPathNames.Lineup)
    },
    label: 'Laguppställning',
    testIdSuffix: 'lineup',
    componentKey: SportinfoPathNames.Lineup,
    getPath: basePath => "".concat(basePath).concat(SportinfoPathNames.Lineup)
  },
  [SportinfoPathNames.Analyze]: {
    to: {
      pathname: "/".concat(SportinfoPathNames.Analyze)
    },
    label: 'Analys',
    testIdSuffix: 'analys',
    componentKey: SportinfoPathNames.Analyze,
    getPath: basePath => "".concat(basePath).concat(SportinfoPathNames.Analyze)
  },
  [SportinfoPathNames.Mutuals]: {
    to: {
      pathname: "/".concat(SportinfoPathNames.Mutuals)
    },
    label: 'Inbördes möten',
    testIdSuffix: 'mutuals',
    componentKey: SportinfoPathNames.Mutuals,
    getPath: basePath => "".concat(basePath).concat(SportinfoPathNames.Mutuals)
  },
  [SportinfoPathNames.Events]: {
    to: {
      pathname: "/".concat(SportinfoPathNames.Events)
    },
    label: 'Händelser',
    testIdSuffix: 'events',
    componentKey: SportinfoPathNames.Events,
    getPath: basePath => "".concat(basePath).concat(SportinfoPathNames.Events)
  },
  [SportinfoPathNames.PlayMakerXstat]: {
    to: {
      pathname: "/".concat(SportinfoPathNames.PlayMakerXstat)
    },
    label: 'xStats',
    testIdSuffix: 'xstat',
    componentKey: SportinfoPathNames.PlayMakerXstat,
    getPath: basePath => "".concat(basePath).concat(SportinfoPathNames.PlayMakerXstat)
  },
  [SportinfoPathNames.EnetpulseStats]: {
    to: {
      pathname: "/".concat(SportinfoPathNames.EnetpulseStats)
    },
    label: 'Enetpulse Stats',
    testIdSuffix: 'enetpulse-stats',
    componentKey: SportinfoPathNames.EnetpulseStats,
    getPath: basePath => "".concat(basePath).concat(SportinfoPathNames.EnetpulseStats)
  },
  [SportinfoPathNames.EnetpulseH2H]: {
    to: {
      pathname: "/".concat(SportinfoPathNames.EnetpulseH2H)
    },
    label: 'Enetpulse H2H',
    testIdSuffix: 'enetpulse-h2h',
    componentKey: SportinfoPathNames.EnetpulseH2H,
    getPath: basePath => "".concat(basePath).concat(SportinfoPathNames.EnetpulseH2H)
  }
};

const regexSportinfoPathnames = new RegExp("(".concat(Object.values(SportinfoPathNames).join('|'), ")$"));
const OutcomeToAlphabeticStringsOverUnder = {
  TotalGoalsOver2Point5: 'over',
  TotalGoalsUnder2Point5: 'under'
};
const EnetpulseWidgets = {
  HeadToHead: 'FW9C4D39174CB1E217',
  TournamentStandings: 'FWEC271DB0F8782CD5',
  MatchFormations: 'FWEF39026C79A7668E',
  MatchIncidents: 'FW8DBCF33CE82E5308',
  WinProbability: 'FW5F44D52E4C61DD0A',
  MatchDetails: 'FW1844AEC637D3A96E',
  MatchStatistics: 'FW44116FAE2E9AEB8F'
};
const toExport = {
  SportinfoPathNames,
  getSelectedOnPaths,
  OutcomeToAlphabeticStrings,
  EventTypes,
  EventTypeId,
  EventTypeField,
  EventTypeDistributionsFallback,
  EventTypeFieldType,
  TypeForEventTypeField,
  AllKnownOutcomes,
  AllKnownOutcomesDescription,
  SportinfoMenuItems,
  SportinfoSportradarWidgets,
  SportradarWidgetToType,
  regexSportinfoPathnames,
  ContentProvider,
  SvsViewType,
  SweHockeyWidgetTypes,
  ExternalIdProviders,
  OutcomeToAlphabeticStringsOverUnder,
  EnetPulseViewType,
  EnetpulseWidgets
};
if (svs.isServer) {
  module.exports = toExport;
} else {
  setGlobal('svs.components.sportinfo.common.constants', toExport);
}

 })(window);