(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/accountservices/player-exclusions/assets/javascripts/exclusions-data.js') >= 0) return;  svs.modules.push('/components/accountservices/player-exclusions/assets/javascripts/exclusions-data.js');

'use strict';

const {
  get: getExclusions
} = svs.accountservices.player_exclusions.activeExlucions;
const getExclusionsAsync = () => new Promise(resolve => {
  getExclusions(exclusion => {
    resolve(exclusion);
  });
});
class ExclusionsData {
  static async getExclusionData(vertical) {
    const activeExclusions = await getExclusionsAsync();
    return new Promise(resolve => {
      if (svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER)) {
        var _svs$accountservices$;
        if ((_svs$accountservices$ = svs.accountservices.player_exclusions.data) !== null && _svs$accountservices$ !== void 0 && _svs$accountservices$.isRegtest) {
          resolve({
            canPlay: true
          });
        }
        if (!activeExclusions) {
          resolve({
            canPlay: true
          });
        }
        if (!svs.accountservices.player_exclusions.utils.isValidSubSystem(vertical)) {
          vertical = 'alla';
        }
        let pause = activeExclusions[vertical];
        if (activeExclusions.isBlocked) {
          resolve({
            icon: 'spelpaus',
            text: svs.accountservices.player_exclusions.template_literals.nar_pause({
              noTitle: true
            }),
            title: 'Du får inte spela!',
            canPlay: false
          });
        } else if (activeExclusions.hasTTRestriction) {
          const toResolve = {
            text: svs.accountservices.player_exclusions.template_literals.tt_restriction(),
            title: 'Just nu kan du inte spela eller sätta in pengar',
            icon: 'help-2',
            canPlay: false
          };
          if (svs.core.detect.feature('ft-call-of-care-update')) {
            toResolve.actions = svs.core.detect.feature('ft-call-of-care-update') && [{
              title: 'Stäng'
            }, {
              title: 'Kontakta oss',
              callback: () => {
                svs.utils.url.navigate(svs.core.urlMapping.get('gameCareContact'));
              }
            }];
          }
          resolve(toResolve);
        } else if (activeExclusions.hasDLRRestriction) {
          const returnUrlString = svs.utils.url.makeInternal(svs.utils.url.getLocation().pathname);
          const navigateString = "".concat(svs.core.urlMapping.get('myProfileLimits'), "?returnUrl=").concat(encodeURIComponent(returnUrlString));
          resolve({
            text: '',
            icon: 'help-2',
            title: 'Innan du kan börja spela behöver du sätta dina insättningsgränser',
            canPlay: false,
            actions: [{
              title: 'Avbryt'
            }, {
              title: 'Sätt gränser',
              callback: () => {
                svs.utils.url.navigate(navigateString);
              }
            }]
          });
        } else if (activeExclusions.hasPause && activeExclusions.hasTotalPause || pause) {
          if (!pause) {
            pause = activeExclusions.alla;
          }
          let parsedDate = dateFns.parseISO(pause.expiry);
          if (pause.vertical === 'Alla spel' && !pause.is24HourExclusion) {
            parsedDate = dateFns.subHours(parsedDate, 12);
          }
          const timeFormat = pause.is24HourExclusion ? ' HH:mm' : '';
          const textFormatTime = timeFormat !== '' && dateFns.format(parsedDate, timeFormat, {
            locale: dateFns.locales.sv
          });
          const textFormatDate = dateFns.format(parsedDate, 'dd/MM, yyyy');
          resolve({
            icon: 'spelpaus',
            text: svs.accountservices.player_exclusions.template_literals.active_pause({
              expiry: pause.is24HourExclusion ? "".concat(textFormatDate, " kl. ").concat(textFormatTime) : textFormatDate,
              isExpired: pause.isExpired,
              noTitle: true,
              vertical: pause.vertical
            }),
            title: pause.isExpired ? 'Spelpaus fortfarande aktiverad!' : 'Spelpaus Aktiverad!',
            canPlay: false
          });
        } else if (activeExclusions.hasGCSRestriction) {
          resolve({
            icon: 'help-2',
            buttonText: 'Okej',
            text: svs.accountservices.player_exclusions.template_literals.gcs_restriction(activeExclusions.gcsData),
            title: 'Just nu kan du inte spela eller sätta in pengar',
            actions: [{
              title: 'Logga ut',
              callback: () => {
                svs.utils.url.navigate("".concat(svs.core.urlMapping.get('logout'), "?returnUrl=/"));
              }
            }, {
              title: 'Okej'
            }],
            canPlay: false
          });
        } else {
          resolve({
            canPlay: true
          });
        }
      } else {
        resolve({
          canPlay: true
        });
      }
    });
  }
}
svs.accountservices.player_exclusions.ExclusionsData = ExclusionsData;

 })(window);