(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/content/image-v2/views/image.js') >= 0) return;  svs.modules.push('/components/content/image-v2/views/image.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.content=_svs.content||{};
_svs.content.image_v2=_svs.content.image_v2||{};
_svs.content.image_v2.templates=_svs.content.image_v2.templates||{};
svs.content.image_v2.templates.image = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " background-image-wrapper image-wrapper-fit";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"cssSelectors") || (depth0 != null ? lookupProperty(depth0,"cssSelectors") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"cssSelectors","hash":{},"data":data,"loc":{"start":{"line":3,"column":111},"end":{"line":3,"column":129}}}) : helper))) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "background-color: "
    + container.escapeExpression((lookupProperty(helpers,"getImageProminentColor")||(depth0 && lookupProperty(depth0,"getImageProminentColor"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"getImageProminentColor","hash":{},"data":data,"loc":{"start":{"line":5,"column":61},"end":{"line":5,"column":89}}}))
    + "; ";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <img class=\"js-image image\" \n    decoding=\"sync\" \n    itemprop=\"image\"\n    sizes=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"sizes") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":11,"column":11},"end":{"line":11,"column":53}}})) != null ? stack1 : "")
    + "\" \n    title=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":12,"column":11},"end":{"line":12,"column":22}}}) : helper))) != null ? stack1 : "")
    + "\" \n    alt=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"altText") || (depth0 != null ? lookupProperty(depth0,"altText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"altText","hash":{},"data":data,"loc":{"start":{"line":13,"column":9},"end":{"line":13,"column":22}}}) : helper))) != null ? stack1 : "")
    + "\"\n    src=\""
    + container.escapeExpression((lookupProperty(helpers,"getNoscriptUrl")||(depth0 && lookupProperty(depth0,"getNoscriptUrl"))||alias2).call(alias1,depth0,undefined,undefined,false,{"name":"getNoscriptUrl","hash":{},"data":data,"loc":{"start":{"line":14,"column":9},"end":{"line":14,"column":55}}}))
    + "\" \n    width=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"aspectRatio") : depth0)) != null ? lookupProperty(stack1,"width") : stack1),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":15,"column":11},"end":{"line":15,"column":81}}})) != null ? stack1 : "")
    + "\"\n    loading=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"loadingLazy") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":16,"column":13},"end":{"line":16,"column":56}}})) != null ? stack1 : "")
    + "\"\n    height=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"aspectRatio") : depth0)) != null ? lookupProperty(stack1,"height") : stack1),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":17,"column":12},"end":{"line":17,"column":85}}})) != null ? stack1 : "")
    + "\"\n     />\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"sizes") || (depth0 != null ? lookupProperty(depth0,"sizes") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"sizes","hash":{},"data":data,"loc":{"start":{"line":11,"column":24},"end":{"line":11,"column":33}}}) : helper)));
},"10":function(container,depth0,helpers,partials,data) {
    return "100vw";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"aspectRatio") : depth0)) != null ? lookupProperty(stack1,"width") : stack1), depth0));
},"14":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"width") || (depth0 != null ? lookupProperty(depth0,"width") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"width","hash":{},"data":data,"loc":{"start":{"line":15,"column":65},"end":{"line":15,"column":74}}}) : helper)));
},"16":function(container,depth0,helpers,partials,data) {
    return "lazy";
},"18":function(container,depth0,helpers,partials,data) {
    return "eager";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"aspectRatio") : depth0)) != null ? lookupProperty(stack1,"height") : stack1), depth0));
},"22":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"height") || (depth0 != null ? lookupProperty(depth0,"height") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"height","hash":{},"data":data,"loc":{"start":{"line":17,"column":68},"end":{"line":17,"column":78}}}) : helper)));
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <picture>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"sourceElements") : depth0),{"name":"each","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":4},"end":{"line":24,"column":13}}})) != null ? stack1 : "")
    + "    <img src=\""
    + container.escapeExpression((lookupProperty(helpers,"getNoscriptUrl")||(depth0 && lookupProperty(depth0,"getNoscriptUrl"))||alias2).call(alias1,depth0,undefined,undefined,false,{"name":"getNoscriptUrl","hash":{},"data":data,"loc":{"start":{"line":25,"column":14},"end":{"line":25,"column":60}}}))
    + "\" alt=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"altText") || (depth0 != null ? lookupProperty(depth0,"altText") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"altText","hash":{},"data":data,"loc":{"start":{"line":25,"column":67},"end":{"line":25,"column":80}}}) : helper))) != null ? stack1 : "")
    + "\"\n      "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"onLoadCallback") : depth0),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":6},"end":{"line":26,"column":76}}})) != null ? stack1 : "")
    + "\n      "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"fallbackImage") : depth0),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":6},"end":{"line":27,"column":81}}})) != null ? stack1 : "")
    + "\n      class=\"js-image image\" \n      decoding=\"sync\"\n      "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"title") : depth0),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":6},"end":{"line":30,"column":46}}})) != null ? stack1 : "")
    + "\n      width=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"aspectRatio") : depth0)) != null ? lookupProperty(stack1,"width") : stack1),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":31,"column":13},"end":{"line":31,"column":83}}})) != null ? stack1 : "")
    + "\"\n      height=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"aspectRatio") : depth0)) != null ? lookupProperty(stack1,"height") : stack1),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":32,"column":14},"end":{"line":32,"column":87}}})) != null ? stack1 : "")
    + "\"\n      loading=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"loadingLazy") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":33,"column":15},"end":{"line":33,"column":58}}})) != null ? stack1 : "")
    + "\">\n  </picture>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <source "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"media") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":12},"end":{"line":22,"column":61}}})) != null ? stack1 : "")
    + "\n      "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"sizes") : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":6},"end":{"line":23,"column":55}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"srcSet") : depth0),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":56},"end":{"line":23,"column":108}}})) != null ? stack1 : "")
    + ">\n";
},"26":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " media=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"media") : depth0), depth0))
    + "\" ";
},"28":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " sizes=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"sizes") : depth0), depth0))
    + "\" ";
},"30":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " srcSet=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"srcSet") : depth0), depth0))
    + "\" ";
},"32":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-image-v2-onload=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"onLoadCallback") || (depth0 != null ? lookupProperty(depth0,"onLoadCallback") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"onLoadCallback","hash":{},"data":data,"loc":{"start":{"line":26,"column":50},"end":{"line":26,"column":68}}}) : helper)))
    + "\"";
},"34":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-image-v2-fallbackimage=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"fallbackImage") || (depth0 != null ? lookupProperty(depth0,"fallbackImage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"fallbackImage","hash":{},"data":data,"loc":{"start":{"line":27,"column":56},"end":{"line":27,"column":73}}}) : helper)))
    + "\"";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "title=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data,"loc":{"start":{"line":30,"column":26},"end":{"line":30,"column":37}}}) : helper))) != null ? stack1 : "")
    + "\" ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias3((lookupProperty(helpers,"setVar")||(depth0 && lookupProperty(depth0,"setVar"))||alias2).call(alias1,"sourceElements",(lookupProperty(helpers,"getSourceElementsData")||(depth0 && lookupProperty(depth0,"getSourceElementsData"))||alias2).call(alias1,depth0,{"name":"getSourceElementsData","hash":{},"data":data,"loc":{"start":{"line":1,"column":26},"end":{"line":1,"column":51}}}),{"name":"setVar","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":53}}}))
    + "\n<div\n  class=\"image-wrapper"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"fitWrapper") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":22},"end":{"line":3,"column":90}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"cssSelectors") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":90},"end":{"line":3,"column":136}}})) != null ? stack1 : "")
    + "\"\n  itemscope itemtype=\"http://schema.org/ImageObject\"\n  style=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"getImageProminentColor")||(depth0 && lookupProperty(depth0,"getImageProminentColor"))||alias2).call(alias1,depth0,{"name":"getImageProminentColor","hash":{},"data":data,"loc":{"start":{"line":5,"column":15},"end":{"line":5,"column":41}}}),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":9},"end":{"line":5,"column":98}}})) != null ? stack1 : "")
    + "\">\n  <meta itemprop=\"url\" content=\""
    + alias3((lookupProperty(helpers,"getNoscriptUrl")||(depth0 && lookupProperty(depth0,"getNoscriptUrl"))||alias2).call(alias1,depth0,undefined,undefined,false,{"name":"getNoscriptUrl","hash":{},"data":data,"loc":{"start":{"line":6,"column":32},"end":{"line":6,"column":78}}}))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"isOriginalImage")||(depth0 && lookupProperty(depth0,"isOriginalImage"))||alias2).call(alias1,depth0,{"name":"isOriginalImage","hash":{},"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":7,"column":27}}}),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(24, data, 0),"data":data,"loc":{"start":{"line":7,"column":2},"end":{"line":35,"column":9}}})) != null ? stack1 : "")
    + "</div>\n\n";
},"useData":true});
Handlebars.partials['components-content-image_v2-image'] = svs.content.image_v2.templates.image;
})(svs, Handlebars);


 })(window);